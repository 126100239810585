import React, { useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import { TextAnimation } from './text-animation';
import { AnimatePresence, motion } from 'framer-motion';
import { Ping } from './ping-icon';
import { useTranslation } from 'react-i18next';

const videos = [
  { name: 'feuerwerk1-30fps.mp4', currentTime: 0.06 },
  { name: 'pizza1-30fps.mp4', currentTime: 4.5 },
  { name: 'watermelon-30fps.mp4', currentTime: 0.0 },
  { name: 'matrix-30fps.mp4', currentTime: 0.0 },
  { name: 'astro-30fps.mp4', currentTime: 0.0 },
  { name: 'race-30fps.mp4', currentTime: 0.0 },
];

gsap.registerPlugin(ScrollTrigger);

export const VideoBanner = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const pingRef = useRef<HTMLDivElement | null>(null);

  const randomIndex = Math.floor(Math.random() * videos.length);

  useEffect(() => {
    const video = videoRef.current;
    const ping = pingRef.current;
    if (!video || !ping) {
      console.error('Entweder Video- oder Ping-Element wurde nicht gefunden.');
      return;
    }

    const src = video.currentSrc || video.src;

    // iOS Fix
    const activateVideoOnTouch = () => {
      // video.play();
      // video.pause();
      video
        .play()
        .then(() => {
          video.pause();
        })
        .catch((error) => {
          console.warn('play() unterbrochen:', error);
        });
    };
    document.documentElement.addEventListener(
      'touchstart',
      activateVideoOnTouch,
      { once: true },
    );

    // GSAP Timeline mit verbesserten Einstellungen
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: video,
        start: 'top top', // Video startet beim Scroll-Anfang
        end: '+=5000', // 5000px Scrollbereich für das Video
        scrub: 1, // Flüssige Synchronisation
      },
    });

    video.addEventListener('loadedmetadata', () => {
      // Starte Video immer von 0 und spiele es über die gesamte Scrollstrecke
      timeline.fromTo(
        video,

        { currentTime: videos[randomIndex].currentTime }, // Start bei 0 Sekunden
        { currentTime: video.duration || 1 }, // Endet bei der vollen Dauer
      );
    });

    // Prefetch video source (optional für Performance)
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const currentTime = video.currentTime;
        video.src = blobURL;
        video.currentTime = currentTime + 0.01;
      });

    // Ping Animation Configuration
    const pingAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: ping,
        start: 'top 80%',
        end: 'top 40%',
        scrub: 1,
      },
    });

    pingAnimation.fromTo(
      ping,
      { opacity: 1 },
      { opacity: 0, ease: 'power1.out' },
    );

    // Cleanup
    return () => {
      document.documentElement.removeEventListener(
        'touchstart',
        activateVideoOnTouch,
      );
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="w-full h-[150vh] -mt-16">
      <div className="absolute top-44 left-2 right-2 flex items-center justify-center flex-col z-10">
        <h2 className="text-white text-2xl sm:text-4xl md:text-6xl text-center font-[Tourney] uppercase">
          {t('header')}
        </h2>
        {/* <h2 className="text-white text-2xl sm:text-7xl font-[Anton] uppercase"> */}
        {/* <h2 className="text-2xl sm:text-7xl text-center text-transparent bg-clip-text bg-gradient-to-r from-violet-500 to-[#0092ca] font-[Anton] uppercase">
          Design & Cyber-Security
        </h2> */}
        <TextAnimation />
      </div>
      <div className="sticky top-0 w-full h-screen">
        <video
          ref={videoRef}
          className="w-screen h-screen object-cover"
          muted
          playsInline
        >
          <source
            src={`/videos/${videos[randomIndex].name}`}
            type="video/mp4"
          />
          Dein Browser unterstützt das Video-Tag nicht.
        </video>
        <AnimatePresence mode="wait">
          <motion.a href="#contact" className="cursor-pointer">
            <motion.div
              ref={pingRef}
              className="absolute bottom-[80px] right-[80px] md:bottom-[90px] md:right-[90px] lg:bottom-[100px] lg:right-[100px] transform z-20"
            >
              <Ping />
            </motion.div>
          </motion.a>
        </AnimatePresence>
      </div>
    </div>
  );
};
