import { MotionValue, motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { TeamMember, teamMembers } from '../data/team-member';

export const Team = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  return (
    <div id="team" className="flex justify-center">
      <div className="max-w-[1184px] w-full px-2">
        <div className="bg-white text-black grid place-items-center"></div>
        <section ref={targetRef} className="flex bg-black text-white">
          <Content content={teamMembers} />
          <Images content={teamMembers} scrollYProgress={scrollYProgress} />
        </section>
        <div className="bg-black text-white grid place-items-center"></div>
      </div>
    </div>
  );
};

const Content = ({ content }: { content: TeamMember[] }) => {
  return (
    <div className="w-full">
      {content.map(({ id, name, designation }) => (
        <div
          key={id}
          className={`p-8 h-screen flex flex-col justify-around bg-gradient-to-b from-black via-slate-900 to-black`}
        >
          <h3 className="text-3xl font-medium">{name}</h3>
          <p className="font-light w-full max-w-md text-xl">{designation}</p>
        </div>
      ))}
    </div>
  );
};

const Images = ({
  content,
  scrollYProgress,
}: {
  content: TeamMember[];
  scrollYProgress: MotionValue<number>;
}) => {
  const top = useTransform(
    scrollYProgress,
    [0, 1],
    [`-${(content.length - 1) * 100}vh`, '0vh'],
  );

  return (
    <div className="h-screen overflow-hidden sticky top-0 w-full">
      <motion.div style={{ top }} className="absolute left-0 right-0">
        {[...content].reverse().map(({ img, id, name }) => (
          <div className="h-screen w-full flex justify-center items-center">
            <img
              key={id}
              alt={name}
              className="h-2/3 w-2/3 object-cover rounded-3xl"
              src={img}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};
