import React from 'react';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-center px-5 pb-2">
      <p>&copy; 2024 twylo. {t('footer')}</p>
    </footer>
  );
};
