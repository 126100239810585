import { motion } from 'framer-motion';

const LOOP_DURATION = 4;

export const Ping = () => {
  return (
    <div className="relative">
      <Logo />
      <Band delay={0} />
      <Band delay={LOOP_DURATION * 0.25} />
      <Band delay={LOOP_DURATION * 0.5} />
      <Band delay={LOOP_DURATION * 0.75} />
    </div>
  );
};

const Logo = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="relative z-10 h-8 w-8 md:h-10 md:w-10 lg:h-14 lg:w-14 text-violet-100"
      initial={{
        opacity: 0,
        scale: 0.85,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      transition={{
        duration: 1,
        ease: 'easeOut',
      }}
    >
      <path d="M22 2L11 13" />
      <path d="M22 2L15 22L11 13L2 9L22 2Z" />
    </motion.svg>
  );
};

const Band = ({ delay }: { delay: number }) => {
  return (
    <motion.span
      style={{
        translateX: '-50%',
        translateY: '-50%',
      }}
      initial={{
        opacity: 0,
        scale: 0.25,
      }}
      animate={{
        opacity: [0, 1, 1, 0],
        scale: 1,
      }}
      transition={{
        repeat: Infinity,
        repeatType: 'loop',
        times: [0, 0.5, 0.75, 1],
        duration: LOOP_DURATION,
        ease: 'linear',
        delay,
      }}
      className="absolute left-[50%] top-[50%] z-0 h-40 w-40 md:h-48 md:w-48 lg:h-56 lg:w-56 rounded-full border-[1px] border-[#0092ca] bg-gradient-to-br from-[#0092ca]/50 to-[#009BDF]/20 shadow-xl shadow-[#0092ca]/40"
    />
  );
};
