import React, { ReactNode, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { TabsFAQ } from "./tabs-faq";
import { TypewriterEffectSmooth } from "./typewriter-effect";
import { useTranslation } from "react-i18next";

export type Question = {
  question: string;
  answer: string;
};

export type Subtopic = {
  topic: string;
  questions: Question[];
};

export type Section = {
  id: number;
  url: string;
  title: string;
  subtitle: string;
  description: string;
  faqs: Subtopic[];
};

const splitSentenceToWords = (sentence: string) => {
  return sentence.split(" ").map((word) => ({ text: word }));
};

export const TextParallaxContent = () => {
  const { t } = useTranslation();

  const sections = t("sections", { returnObjects: true }) as Section[];

  const filteredSections = sections.filter(
    (section) => section.title !== "Team" && section.title !== "Contact"
  );

  return (
    <div className="flex justify-center">
      <div className="w-[1200px]">
        {filteredSections.map((section) => (
          <InnerParallaxContent key={section.title} section={section} />
        ))}
      </div>
    </div>
  );
};
const InnerParallaxContent = ({ section }: { section: Section }) => {
  const sectionTabs = section.faqs.map((faq) => faq.topic);
  const [selectedTap, setSelectedTap] = useState(sectionTabs[0]);
  const sectionFaqs = section.faqs;
  const selectedFaq = section.faqs.find((faq) => faq.topic === selectedTap);

  return (
    <div id={section.title.replace(/\s+/g, "-").toLowerCase()}>
      <ParallaxContent
        imgUrl={section.url}
        subheading={section.subtitle}
        heading={section.title}
      >
        <div
          className="px-4 pb-16 text-center text-lg sm:text-xl"
          dangerouslySetInnerHTML={{ __html: section.description }}
        />
        {selectedFaq && (
          <TabsFAQ
            tabs={sectionTabs}
            selected={selectedTap}
            setSelected={setSelectedTap}
            sectionFaqs={sectionFaqs}
          />
        )}
      </ParallaxContent>
    </div>
  );
};

const IMG_PADDING = 12;

const ParallaxContent = ({
  imgUrl,
  subheading,
  heading,
  children,
}: {
  imgUrl: string;
  subheading: string;
  heading: string;
  children: ReactNode;
}) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
        background: "radial-gradient(closest-side at 50% 44%, #38266e, #000)",
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }: { imgUrl: string }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({
  subheading,
  heading,
}: {
  subheading: string;
  heading: string;
}) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <div className="hidden sm:block">
        <TypewriterEffectSmooth words={splitSentenceToWords(heading)} />
      </div>

      <p className="block sm:hidden text-center text-4xl font-bold md:text-7xl">
        {heading}
      </p>

      <p className="mb-2 px-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
    </motion.div>
  );
};
