import React from 'react';
import {
  SiCss3,
  SiFramer,
  SiHtml5,
  SiJavascript,
  SiReact,
  SiTailwindcss,
} from 'react-icons/si';
import { motion } from 'framer-motion';

export const SwapLogos = () => {
  return (
    <section>
      <div className="mx-auto grid max-w-3xl grid-cols-3 ">
        <Spinner
          top={<SiTailwindcss className="text-[#0DA5E9]" />}
          bottom={<SiHtml5 className="text-[#DD4A25]" />}
        />
        <Spinner
          top={<SiFramer className="text-[#0095FF]" />}
          bottom={<SiCss3 className="text-[#254BDD]" />}
        />

        <Spinner
          top={<SiReact className="text-[#58C4DC]" />}
          bottom={<SiJavascript className="text-[#EFD81D]" />}
        />
      </div>
    </section>
  );
};

const TRANSITION = {
  ease: 'easeInOut',
  duration: 10,
  repeat: Infinity,
  times: [0, 0.3, 0.4, 0.7, 0.8, 1],
};

const Spinner = ({
  top,
  bottom,
}: {
  top: React.ReactNode;
  bottom: React.ReactNode;
}) => {
  return (
    <div className="relative h-12 w-full overflow-hidden  text-2xl">
      {/* TOP SPINNER */}
      <motion.div
        style={{
          y: '-50%',
          x: '-50%',
        }}
        animate={{
          rotate: ['0deg', '0deg', '180deg', '180deg', '360deg', '360deg'],
        }}
        transition={TRANSITION}
        className="absolute left-1/2 z-10 h-12 w-[50px] overflow-hidden rounded-full bg-neutral-900 ring-[1px] ring-neutral-700"
      >
        <div
          style={{
            bottom: 0,
            transform: 'translateY(50%) translateX(-50%)',
          }}
          className="absolute left-1/2"
        >
          {top}
        </div>
        <div
          style={{
            top: 0,
            transform: 'translateY(-50%) translateX(-50%) rotate(180deg)',
          }}
          className="absolute left-1/2"
        >
          {bottom}
        </div>
      </motion.div>

      {/* BOTTOM SPINNER */}
      <motion.div
        style={{
          y: '50%',
          x: '-50%',
        }}
        animate={{
          rotate: ['0deg', '0deg', '180deg', '180deg', '360deg', '360deg'],
        }}
        transition={TRANSITION}
        className="absolute left-1/2 z-10 h-12 w-[50px] overflow-hidden rounded-full bg-neutral-900 ring-[1px] ring-neutral-700"
      >
        <div
          style={{
            bottom: 0,
            transform: 'translateY(50%) translateX(-50%) rotate(180deg)',
          }}
          className="absolute left-1/2"
        >
          {bottom}
        </div>
        <div
          style={{
            top: 0,
            transform: 'translateY(-50%) translateX(-50%)',
          }}
          className="absolute left-1/2"
        >
          {top}
        </div>
      </motion.div>
    </div>
  );
};
