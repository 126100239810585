import React, { useEffect, useState, memo } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const TextReveal = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center rounded-2xl w-full pt-40">
      <TextRevealCard
        text={t('slogan1')}
        revealText={t('slogan2')}
      ></TextRevealCard>
    </div>
  );
};

export const TextRevealCard = ({
  text,
  revealText,
  children,
}: {
  text: string;
  revealText: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  const [widthPercentage, setWidthPercentage] = useState(0);
  const [direction, setDirection] = useState(1); // 1 = nach rechts, -1 = nach links
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setWidthPercentage((prev) => {
          const nextValue = prev + direction * 2; // Bewegungsgeschwindigkeit
          if (nextValue >= 100) {
            setIsPaused(true);
            setTimeout(() => {
              setIsPaused(false);
              setDirection(-1); // Richtung wechseln
            }, 1000); // Pause an der rechten Seite
            return 100;
          } else if (nextValue <= 0) {
            setIsPaused(true);
            setTimeout(() => {
              setIsPaused(false);
              setDirection(1); // Richtung wechseln
            }, 1000); // Pause an der linken Seite
            return 0;
          }
          return nextValue;
        });
      }
    }, 50); // Intervall der Bewegung
    return () => clearInterval(interval);
  }, [direction, isPaused]);

  const rotateDeg = (widthPercentage - 50) * 0.1;

  return (
    <div className="w-[40rem] rounded-lg p-8 relative overflow-hidden">
      {children}

      <div className="h-40 relative flex items-center overflow-hidden">
        <motion.div
          style={{
            width: '100%',
          }}
          animate={{
            opacity: widthPercentage > 0 ? 1 : 0,
            clipPath: `inset(0 ${100 - widthPercentage}% 0 0)`,
          }}
          transition={{ duration: 0.4 }}
          className="absolute bg-black z-20 will-change-transform"
        >
          <p
            style={{
              textShadow: '4px 4px 15px rgba(0,0,0,0.5)',
            }}
            className="text-2xl sm:text-[3rem] text-center py-10 font-bold text-white bg-clip-text text-transparent bg-black"
          >
            {revealText}
          </p>
        </motion.div>
        <motion.div
          animate={{
            left: `${widthPercentage}%`,
            rotate: `${rotateDeg}deg`,
            opacity: widthPercentage > 0 && widthPercentage < 100 ? 1 : 0, // Unsichtbar, wenn ganz links oder rechts
          }}
          transition={{ duration: 0.4 }}
          className="h-40 w-[8px] bg-gradient-to-b from-transparent via-neutral-800 to-transparent absolute z-50 will-change-transform"
        ></motion.div>

        <div className="relative overflow-hidden flex items-center justify-center h-full w-full">
          <p className="text-2xl sm:text-[3rem] py-10 font-bold bg-clip-text text-transparent bg-[#686874]">
            {text}
          </p>
          <MemoizedStars />
        </div>
      </div>
    </div>
  );
};

const Stars = () => {
  const randomMove = () => Math.random() * 4 - 2;
  const randomOpacity = () => Math.random();
  const random = () => Math.random();
  return (
    <div className="absolute inset-0">
      {[...Array(80)].map((_, i) => (
        <motion.span
          key={`star-${i}`}
          animate={{
            top: `calc(${random() * 100}% + ${randomMove()}px)`,
            left: `calc(${random() * 100}% + ${randomMove()}px)`,
            opacity: randomOpacity(),
            scale: [1, 1.2, 0],
          }}
          transition={{
            duration: random() * 10 + 20,
            repeat: Infinity,
            ease: 'linear',
          }}
          style={{
            position: 'absolute',
            top: `${random() * 100}%`,
            left: `${random() * 100}%`,
            width: `2px`,
            height: `2px`,
            backgroundColor: 'white',
            borderRadius: '50%',
            zIndex: 1,
          }}
          className="inline-block"
        ></motion.span>
      ))}
    </div>
  );
};

export const MemoizedStars = memo(Stars);
