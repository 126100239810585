import React, { Suspense } from 'react';
import { Header } from './components/header';
import { VideoBanner } from './components/video-banner';
import { HorizontalScroll } from './components/horizontalScroll';
import { Footer } from './components/footer';
import { TextParallaxContent } from './components/parllax-content';
import { BackgroundBeams } from './components/background-beams';
import Contact from './components/contact';
import { Team } from './components/team';
import { SwapLogos } from './components/swap-logos';
import { ShuffleLoaderScreen } from './components/shuffle-loader';

function App() {
  return (
    <div>
      <Header />
      <Suspense fallback={<ShuffleLoaderScreen />}>
        <VideoBanner />
        {/* <HorizontalScroll /> */}
        <TextParallaxContent />
        <Team />
        <Contact />
      </Suspense>
      <Footer />
      <SwapLogos />
      <BackgroundBeams />
    </div>
  );
}

export default App;
