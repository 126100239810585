import {
  AnimatePresence,
  motion,
  useAnimation,
  useInView,
} from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiPlus } from "react-icons/fi";
import useMeasure from "react-use-measure";
import { Question, Subtopic } from "./parllax-content";

export const TabsFAQ = ({
  tabs,
  selected,
  setSelected,
  sectionFaqs,
}: {
  tabs: string[];
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  sectionFaqs: Subtopic[];
}) => {
  return (
    <section className="overflow-hidden px-4 py-12 text-slate-50">
      <Heading />
      <Tabs tabs={tabs} selected={selected} setSelected={setSelected} />
      <Questions selected={selected} sectionFaqs={sectionFaqs} />
    </section>
  );
};

const Heading = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
        hidden: { x: -100, opacity: 0, transition: { duration: 0.6 } },
      }}
      className="relative z-10 flex flex-col items-center justify-center"
    >
      <span className="mb-8 text-5xl font-bold">FAQs</span>
    </motion.div>
  );
};

const Tabs = ({
  tabs,
  selected,
  setSelected,
}: {
  tabs: string[];
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
        hidden: { x: 100, opacity: 0, transition: { duration: 0.6 } },
      }}
      className="relative z-10 flex flex-wrap items-center justify-center gap-4"
    >
      {tabs.map((tab) => (
        <button
          onClick={() => setSelected(tab)}
          className={`relative overflow-hidden whitespace-nowrap rounded-md border-[1px] px-3 py-1.5 text-sm font-medium transition-colors duration-500 ${
            selected === tab
              ? "border-violet-500 text-slate-50"
              : "border-slate-600 bg-transparent text-slate-400"
          }`}
          key={tab}
        >
          <span className="relative z-10">{tab}</span>
          <AnimatePresence>
            {selected === tab && (
              <motion.span
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                exit={{ y: "100%" }}
                transition={{
                  duration: 0.5,
                  ease: "backIn",
                }}
                className="absolute inset-0 z-0 bg-gradient-to-r from-violet-500 to-[#0092ca]"
              />
            )}
          </AnimatePresence>
        </button>
      ))}
    </motion.div>
  );
};

const Questions = ({
  selected,
  sectionFaqs,
}: {
  selected: string;
  sectionFaqs: Subtopic[];
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { x: 0, opacity: 1, transition: { duration: 0.6 } },
        hidden: { x: -100, opacity: 0, transition: { duration: 0.6 } },
      }}
      className="mx-auto mt-12 max-w-3xl"
    >
      {/* <div className="mx-auto mt-12 max-w-3xl"> */}
      <AnimatePresence mode="wait">
        {sectionFaqs.map((faq) => {
          return selected === faq.topic ? (
            <motion.div
              key={faq.topic}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 0.5,
                ease: "backIn",
              }}
              className="space-y-4"
            >
              {faq.questions.map((q, idx) => (
                <QuestionAccordion key={idx} {...q} />
              ))}
            </motion.div>
          ) : null;
        })}
      </AnimatePresence>
      {/* </div> */}
    </motion.div>
  );
};

const QuestionAccordion = ({ question, answer }: Question) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className={`rounded-xl border-[1px] border-slate-700 px-4 transition-colors ${
        open ? "bg-slate-800" : "bg-slate-900"
      }`}
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="flex w-full items-center justify-between gap-4 py-4"
      >
        <span
          className={`text-left text-lg font-medium transition-colors ${
            open ? "text-slate-50" : "text-slate-400"
          }`}
        >
          {question}
        </span>
        <motion.span
          variants={{
            open: { rotate: 45 },
            closed: { rotate: 0 },
          }}
          animate={open ? "open" : "closed"}
          transition={{ duration: 0.2 }}
        >
          <FiPlus
            className={`text-2xl transition-colors ${
              open ? "text-slate-50" : "text-slate-400"
            }`}
          />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : 0,
          marginBottom: open ? 24 : 0,
        }}
        className="overflow-hidden text-base text-slate-400"
      >
        <p ref={ref}>{answer}</p>
      </motion.div>
    </motion.div>
  );
};
