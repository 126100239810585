import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const TextAnimation = () => {
  const { t } = useTranslation();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const words = t('animatedWords', { returnObjects: true }) as string[];

  useEffect(() => {
    const perCharacterDelay = 50; // Kürzere Verzögerung pro Buchstabe
    const animationDuration = 0.5; // Kürzere Animationsdauer pro Buchstabe
    const currentWord = words[currentWordIndex];
    const totalLetters = currentWord.length;

    // Gesamtdauer der Animation berechnen
    const totalAnimationTime =
      totalLetters * (animationDuration + perCharacterDelay / 1000);

    // Zusätzliche Zeit, um das Wort lesbar zu machen
    const extraDisplayTime = 2; // Sekunden

    const displayTime = (totalAnimationTime + extraDisplayTime) * 500; // In Millisekunden

    const timeout = setTimeout(() => {
      setCurrentWordIndex((prev) => (prev + 1) % words.length);
    }, displayTime);

    return () => clearTimeout(timeout);
  }, [currentWordIndex, words]);

  const letterAnimation = {
    initial: {
      y: 80,
      opacity: 0,
      rotateX: -90,
    },
    animate: (i: number) => ({
      y: 0,
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.5,
        delay: i * 0.05,
      },
    }),
    exit: (i: number) => ({
      y: -50,
      opacity: 0,
      rotateX: 90,
      transition: {
        duration: 0.5,
        delay: i * 0.03,
      },
    }),
  };

  return (
    <div className="container min-h-[100px] sm:min-h-[200px] flex items-center justify-center">
      <div className="wrapper uppercase text-xl sm:text-2xl md:text-7xl text-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentWordIndex}
            className="flex justify-center overflow-hidden"
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {words[currentWordIndex].split('').map((letter, i) => (
              <motion.span
                key={letter + i}
                variants={letterAnimation}
                custom={i}
                style={{
                  display: 'inline-block',
                  transformStyle: 'preserve-3d',
                  backfaceVisibility: 'hidden',
                }}
                className="min-w-[0.2em]"
              >
                {letter === ' ' ? '\u00A0' : letter}
              </motion.span>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
