export type TeamMember = {
  id: number;
  name: string;
  img: string;
  designation: string;
};

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "Daniel Wulfing",
    img: "/team/Daniel.png",
    designation: "Founder / Developer",
  },
  {
    id: 2,
    name: "Viktor Grünwald",
    img: "/team/Viktor.png",
    designation: "Chief-Developer",
  },
  {
    id: 3,
    name: "Tony Schroeder",
    img: "/team/Tony.png",
    designation: "Developer / UI-UX Designer",
  },
];
