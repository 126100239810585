import { Dispatch, SetStateAction, useState } from "react";
import { FiX } from "react-icons/fi";
import { motion } from "framer-motion";
import { Link } from "./hover-image-links";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { Section } from "./parllax-content";

export const Nav = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const sections = t("sections", { returnObjects: true }) as Section[];

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
  };

  return (
    <motion.nav
      className="fixed top-0 bottom-0 w-full bg-neutral-950 overflow-x-hidden"
      animate={isOpen ? "open" : "closed"}
      variants={navVariants}
      initial="closed"
    >
      <motion.button
        className="text-3xl bg-white text-black hover:text-indigo-500 border-[1px] border-transparent hover:border-indigo-500 transition-colors p-4 rounded-full fixed top-8 right-8 z-30"
        whileHover={{ rotate: "180deg" }}
        onClick={() => setIsOpen(false)}
        whileTap={{ scale: 0.9 }}
      >
        <FiX />
      </motion.button>
      <motion.div variants={linkWrapperVariants} className="">
        <section className=" p-4 md:p-8">
          <div className="mx-auto max-w-5xl">
            <div role="tablist" className="tabs tabs-boxed w-fit bg-opacity-25">
              <button
                role="tab"
                className={`tab ${
                  activeLanguage === "de"
                    ? "tab-active !bg-[#0092ca] !text-white"
                    : "hover:text-white"
                }`}
                onClick={() => changeLanguage("de")}
              >
                De
              </button>
              <button
                role="tab"
                className={`tab ${
                  activeLanguage === "en"
                    ? "tab-active !bg-[#0092ca] !text-white"
                    : "hover:text-white"
                }`}
                onClick={() => changeLanguage("en")}
              >
                En
              </button>
              <button
                role="tab"
                className={`tab ${
                  activeLanguage === "nl"
                    ? "tab-active !bg-[#0092ca] !text-white"
                    : "hover:text-white"
                } `}
                onClick={() => changeLanguage("nl")}
              >
                Nl
              </button>
            </div>
            {sections.map((section) => (
              <Link
                key={section.title}
                heading={section.title}
                imgSrc={section.url}
                subheading=""
                href={section.title.replace(/\s+/g, "-").toLowerCase()}
                onClick={() => setIsOpen(false)}
              />
            ))}
          </div>
        </section>
      </motion.div>
    </motion.nav>
  );
};

const navVariants = {
  open: {
    x: "0%",
    borderTopLeftRadius: "0vw",
    borderBottomLeftRadius: "0vw",
    opacity: 1,
  },
  closed: {
    x: "100%",
    borderTopLeftRadius: "50vw",
    borderBottomLeftRadius: "50vw",
    opacity: 0,
  },
};

const linkWrapperVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
