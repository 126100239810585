import i18n from '../i18n';
import React, { useEffect, useRef, useState } from 'react';
import { HamburgerButton } from './hamburger-button';
import { motion } from 'framer-motion';
import { FiMenu, FiX, FiArrowRight } from 'react-icons/fi';
import { Nav } from './liquid-side-nav';

export const Header = () => {
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Typ als HTMLDivElement festlegen
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node; // Event-Target als Node typisieren
      // Überprüfen, ob dropdownRef definiert ist und der Zielklick außerhalb des Dropdowns war
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
    setIsDropdownOpen(false); // Schließt das Dropdown nach der Sprachauswahl
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="flex justify-center sticky top-0 bg-transparent z-50">
      <header className="max-w-[1200px] w-full text-3xl font-semibold flex justify-between items-center p-2 h-16 text-[#0092ca]">
        <a href="/" className="">
          <img
            className="h-12"
            loading="lazy"
            src="/logo.png"
            alt="Logo"
            typeof="foaf:Image"
          />
        </a>
        <h1 className="text-[#0092ca] drop-shadow-2xl">Twylo</h1>

        <motion.button
          whileHover={{ rotate: '180deg' }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsOpen(true)}
          className="text-2xl bg-[#0092ca] text-white hover:text-indigo-500 transition-colors p-[12px] rounded-full"
        >
          <FiMenu />
        </motion.button>
      </header>
      <Nav isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
