export const impressumData = {
  heading: 'Impressum',
  sections: [
    {
      title: 'Angaben gemäß § 5 TMG',
      content: [
        {
          subtitle: '<strong>twylo b.v.</strong>',
          value: 'Kaldenkerkerkerweg 20<br />5913 AE Venlo',
        },
      ],
    },
    {
      title: 'Kontakt',
      content: [
        {
          subtitle: 'Telefon',
          value: '+31 61714 7224',
        },
        {
          subtitle: 'E-Mail',
          value: 'mail@twylo.net',
        },
      ],
    },
    {
      title: 'Uitreksel Handelsregister',
      content: [
        {
          subtitle: '',
          value: 'Statutaire zetel: gemeente Venlo<br />RSIN: 866658087',
        },
      ],
    },
    {
      title: 'KvK-Nummer',
      content: [
        {
          subtitle: '',
          value: '94158185',
        },
      ],
    },
  ],
};
